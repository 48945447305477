import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import PageHero from '../components/PageHero'
import UnsubscribeForm from "../components/Unsubscribe"
import { ImHome } from "react-icons/im"
import { Link } from "gatsby"

const UnsubscribePage = (props) => {
    return (
        <>
            <Layout location={props.location}>
                <SEO title="Unsubscribe" description="Unsubscribe from mailing list" image="" />{/* title, description, image, article */}
                <PageHero heading="Unsubscribe" />
                <section className="container mx-auto max-w-7xl text-center pt-4">
                    <div className="text-left px-5 md:px-8 pt-4 pb-4">
                      <ul className="text-left font-mono text-xs md:text-base">
                        <li className="inline-block border-black border-r-2">
                          <Link to="/">
                              <span className="inline-block pr-2"><ImHome /></span>
                              <span className="px-2">Home</span>
                          </Link>
                        </li>
                        <li className="inline-block">
                          <span className="px-2">Unsubscribe</span>
                        </li>
                      </ul>
                    </div>
                    <UnsubscribeForm />
                </section>
            </Layout>
        </>
    )
}

export default UnsubscribePage